<template>
  <v-card class="pa-5">
    <h1 class="mb-5">By pressing "Delete" below you are agreeing to the following</h1>
    <p><strong class="primary--text">1- &nbsp;</strong>Delete all your trips</p>
    <p><strong class="primary--text">2- &nbsp;</strong>Delete all your profile data</p>
    <p><strong class="primary--text">3- &nbsp;</strong>Delete all your booking data</p>

    <v-btn :loading="deleteBtnLoad" color="primary" @click="deleteUser()">Delete</v-btn>
    <v-btn color="primary" class="ml-3" text @click="$router.push('/profile/about')">Return</v-btn>
  </v-card>
</template>

<script>
import user from '@/web_services/user'
export default {
  data () {
    return {
      deleteBtnLoad: false
    }
  },
  methods: {
    deleteUser () {
      this.deleteBtnLoad = true
      user.delete().then(() => {
      }).catch(error => {
        this.$emit('error', error)
      }).finally(() => {
        this.$store.dispatch('removeUser')
        this.$cookies.remove('gt_user_token')
        this.$router.push({ path: '/auth' })
        this.deleteBtnLoad = false
      })
    }
  }
}
</script>
